var L = require('leaflet')
var toGeoJSON = require('@mapbox/togeojson');

require('leaflet.markercluster')
require('leaflet-gesture-handling')
require('leaflet.featuregroup.subgroup');

let map = null
//const mapCenter = [-21.1918, 55.3739]
const mapCenter = [-21.099285, 55.480270]

let markersCluster = {
    parentGroup: null,
    subGroups: {
        "Diagonale des Fous": null,
        "Mascareignes": null,
        "Trail de Bourbon": null,
        "Zembrocal Trail": null,
        "Metis Trail": null
    }
}
let currentIdDossard = null
let dataMultipleRunner = null
let dataOneRunner = null
let randomRunnerLatLng = null
let tracingOfCurrentRouteRunner = null
let refSetTimeoutMultipleRunner = null
let refSetTimeoutOneRunner = null
let racesTracingLayers = []
let startingFlagLayers = []
//let refreshMapInterval = 300000 //mettre 300000 pour 5 minutes
let currentMapMode = "trackingMultipleRunner"
let mapIsInit = false
let flyIsInit = false
let maxRowsRankingTable = 10
//let baseURL = "https://gr2022.digital1to1.fr" //DEV
let baseURL = "https://grandraid.sfr.re"; //PROD
//let baseURLWS = "/wsgr/index.php?" //DEV
//let baseURLWS = "/wsgr/index.php?" //PROD
//let multipleRunnerApiRoute = baseURLWS+"/remote/chargeCoureurPosition" //versiondb
let multipleRunnerApiRoute = "/position/lescoureurs.json" //versionjson
//let updatedMultipleRunnerApiRoute = baseURLWS+"/remote/updateCoureurPosition"
//let oneRunnerApiRoute = baseURLWS+"/remote/chargeThisCoureurPosition"  //versiondb
let oneRunnerApiRoute = "/position/" //versionjson
//let classementApiRoute = baseURLWS+"/remote/chargeClassement"  //versiondb
let classementApiRoute = "/position/classement.json" //versionjson



const markersByClass = {};
/*const customIcon = L.icon({
    iconUrl: 'assets/img/blueMarker.svg', // Chemin vers votre image PNG
    iconSize: [38, 38], // Taille de l'icône
    iconAnchor: [19, 38], // Point de l'icône qui correspond à la position du marqueur
    popupAnchor: [0, -38] // Point de l'icône où la popup sera ancrée
});*/

// Exemple de points GPS avec latitude, longitude et un message popup
const pointsGPS = [
    { lat: -21.33975, lng: 55.45966, popupText: "St Pierre Ravine Blanche", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.31272, lng: 55.54590, popupText: "Domaine Vidot", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.24580, lng: 55.59408, popupText: "Notre Dame de la Paix", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.20079, lng: 55.62105, popupText: "Parking Aire Nez de Boeuf", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.16127, lng: 55.57098, popupText: "Mare à Boue", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.12720, lng: 55.50587, popupText: "Kerveguen", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.11134, lng: 55.49528, popupText: "Croisée coteaux Kerveguen", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.12336, lng: 55.48316, popupText: "Le Bloc", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.12559, lng: 55.47233, popupText: "Plateau des chênes", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.13639, lng: 55.47432, popupText: "Cilaos (Stade)", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.11769, lng: 55.45011, popupText: "Sentier Taïbit (début)", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.10351, lng: 55.43416, popupText: "Marla", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.06615, lng: 55.45099, popupText: "Plaine des Merles", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.05498, lng: 55.45460, popupText: "Sentier Scout", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.01987, lng: 55.42491, popupText: "Aurère", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.02214, lng: 55.40437, popupText: "Passerelle d'Oussy", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.04245, lng: 55.39306, popupText: "Ilet des orangers", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.05112, lng: 55.38089, popupText: "Piton des orangers", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -20.97252, lng: 55.32462, popupText: "Ilet Savannah", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -21.00310, lng: 55.39250, popupText: "Deux Bras", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -20.96356, lng: 55.35862, popupText: "Chemin Ratinaud", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -20.92716, lng: 55.33784, popupText: "La possession", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -20.89583, lng: 55.37733, popupText: "Grande Chaloupe", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -20.90835, lng: 55.42361, popupText: "Le Colorado", className:"ravitos_grr", customIcon:"yellowMarker.svg" },
    { lat: -20.88499, lng: 55.44212, popupText: "St Denis La Redoute", className:"ravitos_grr", customIcon:"yellowMarker.svg" },

    { lat: -21.13630, lng: 55.47418, popupText: "Cilaos (Stade)", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -21.11771, lng: 55.45017, popupText: "Sentier Taïbit", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -21.10350, lng: 55.43415, popupText: "Marla", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -21.06616, lng: 55.45106, popupText: "Plaine des Merles", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -21.05495, lng: 55.45452, popupText: "Sentier Scout", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -21.01978, lng: 55.42493, popupText: "Aurère", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -21.02211, lng: 55.40439, popupText: "Passerelle d'Oussy", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -21.04246, lng: 55.39317, popupText: "Ilet des orangers", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -21.05108, lng: 55.38097, popupText: "Piton des orangers", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -20.97251, lng: 55.32463, popupText: "Ilet Savannah", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -20.96356, lng: 55.35866, popupText: "Chemin Ratinaud", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -20.92696, lng: 55.33792, popupText: "La possession", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -20.89583, lng: 55.37733, popupText: "Grande Chaloupe", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -20.90827, lng: 55.42360, popupText: "Le Colorado", className:"ravitos_tdb", customIcon:"blueMarker.svg" },
    { lat: -20.88497, lng: 55.44212, popupText: "St Denis La Redoute", className:"ravitos_tdb", customIcon:"blueMarker.svg" },

    { lat: -21.06976, lng: 55.52097, popupText: "Hell-Bourg", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -21.06616, lng: 55.45106, popupText: "Plaine des Merles", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -21.05486, lng: 55.45454, popupText: "Sentier Scout", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -21.01990, lng: 55.42489, popupText: "Aurère", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -21.00310, lng: 55.39243, popupText: "Deux Bras", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -20.98055, lng: 55.37210, popupText: "Dos d'Ane", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -20.96357, lng: 55.35869, popupText: "Chemin Ratinaud", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -20.92695, lng: 55.33795, popupText: "La possession", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -20.89597, lng: 55.37710, popupText: "Grande Chaloupe", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -20.90812, lng: 55.42366, popupText: "Le Colorado", className:"ravitos_mas", customIcon:"greenMarker.svg" },
    { lat: -20.88506, lng: 55.44212, popupText: "St Denis La Redoute", className:"ravitos_mas", customIcon:"greenMarker.svg" },

    { lat: -21.37893, lng: 55.61912, popupText: "Saint Joseph", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.31300, lng: 55.64112, popupText: "Grand Galet Ecole", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.23171, lng: 55.64874, popupText: "Pas de sable", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.20089, lng: 55.62077, popupText: "Parking Aire Nez de Boeuf", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.16111, lng: 55.57088, popupText: "Mare à Boue", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.12717, lng: 55.50585, popupText: "Coteaux Kerveguen", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.11136, lng: 55.49526, popupText: "Croisée coteaux Kerveguen", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.12341, lng: 55.48321, popupText: "Le Bloc", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.12561, lng: 55.47241, popupText: "Plateau des chênes", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.13646, lng: 55.47434, popupText: "Cilaos (Stade)", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.11761, lng: 55.45014, popupText: "Sentier Taïbit", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.10349, lng: 55.43420, popupText: "Marla", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.06617, lng: 55.45104, popupText: "Plaine des Merles", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.05495, lng: 55.45453, popupText: "Sentier Scout", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.01981, lng: 55.42490, popupText: "Aurère", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -21.00303, lng: 55.39235, popupText: "Deux Bras", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -20.98059, lng: 55.37213, popupText: "Dos d'Ane", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -20.96355, lng: 55.35864, popupText: "Chemin Ratinaud", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -20.92702, lng: 55.33811, popupText: "La possession", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -20.89579, lng: 55.37735, popupText: "Grande Chaloupe", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -20.90836, lng: 55.42355, popupText: "Le Colorado", className:"ravitos_zem", customIcon:"redMarker.svg" },
    { lat: -20.88498, lng: 55.44212, popupText: "St Denis La Redoute", className:"ravitos_zem", customIcon:"redMarker.svg" },

    { lat: -21.01589, lng: 55.26093, popupText: "Saint Paul", className:"ravitos_mtr", customIcon:"blueMarker.svg" },
    { lat: -21.01812, lng: 55.30254, popupText: "Ecole Bellemène", className:"ravitos_mtr", customIcon:"blueMarker.svg" },
    { lat: -20.97247, lng: 55.32560, popupText: "Ilet Savannah", className:"ravitos_mtr", customIcon:"blueMarker.svg" },
    { lat: -20.96361, lng: 55.35862, popupText: "Chemin Ratinaud", className:"ravitos_mtr", customIcon:"blueMarker.svg" },
    { lat: -20.92708, lng: 55.33767, popupText: "La possession", className:"ravitos_mtr", customIcon:"blueMarker.svg" },
    { lat: -20.89583, lng: 55.37733, popupText: "Grande Chaloupe", className:"ravitos_mtr", customIcon:"blueMarker.svg" },
    { lat: -20.90835, lng: 55.42361, popupText: "Le Colorado", className:"ravitos_mtr", customIcon:"blueMarker.svg" },
    { lat: -20.88497, lng: 55.44234, popupText: "St Denis La Redoute", className:"ravitos_mtr", customIcon:"blueMarker.svg" }


];




const raceConfigs = [
    {
        idConfig: "Mascareignes",
        markerPopupTitle: 'LA MASCAREIGNES',
        gpxURL: 'assets/gpx/trace-MAS2024.gpx',
        color: '#669232',
        outlineColor: '#126F16',
        class: 'gr-mas',
        classText: 'gr-text-mas',
        classbg: 'gr-bg-mas',
        strokeWidth: 5,
        startingRace: {
            flag: 'assets/img/starting-flag.svg',
            position: {
                lat: -21.06976,
                long: 55.52097
            }

        },
        endingRace: {
            flag: 'assets/img/ending-flag.svg',
            position: {
                lat: -20.88506,
                long: 55.44243
            }

        }
    },
    {
        idConfig: "Trail de Bourbon",
        markerPopupTitle: 'TRAIL DE BOURBON',
        gpxURL: 'assets/gpx/trace-TDB2024.gpx',
        color: '#585FAB',
        outlineColor: '#222760',
        class: 'gr-tdb',
        classText: 'gr-text-tdb',
        classbg: 'gr-bg-tdb',
        strokeWidth: 5,
        startingRace: {
            flag: 'assets/img/starting-flag.svg',
            position: {
                lat: -21.1363,
                long: 55.4743
            }

        },
        endingRace: {
            flag: 'assets/img/ending-flag.svg',
            position: {
                lat: -20.88506,
                long: 55.44243
            }

        }
    },
    {
        idConfig: "Zembrocal Trail",
        markerPopupTitle: 'ZEMBROCAL TRAIL',
        gpxURL: 'assets/gpx/trace-ZEM2024.gpx',
        color: '#D12E26',
        outlineColor: '#851913',
        class: 'gr-zem',
        classText: 'gr-text-zem',
        classbg: 'gr-bg-zem',
        strokeWidth: 5,
        startingRace: {
            flag: 'assets/img/starting-flag.svg',
            position: {
                lat: -21.38323,
                long: 55.61949
            }

        },
        endingRace: {
            flag: 'assets/img/ending-flag.svg',
            position: {
                lat: -20.88506,
                long: 55.44243
            }

        }
    },
    {
        idConfig: "Diagonale des Fous",
        markerPopupTitle: 'DIAGONALE DES FOUS',
        gpxURL: 'assets/gpx/trace-GR2024.gpx',
        color: '#F7CF45',
        outlineColor: '#9F8119',
        class: 'gr-ddf',
        classText: 'gr-text-ddf',
        classbg: 'gr-bg-ddf',
        strokeWidth: 5,
        startingRace: {
            flag: 'assets/img/starting-flag.svg',
            position: {
                lat: -21.33905,
                long: 55.45888
            }

        },
        endingRace: {
            flag: 'assets/img/ending-flag.svg',
            position: {
                lat: -20.88506,
                long: 55.44243
            }
        }
    },
    {
        idConfig: "Metis Trail",
        markerPopupTitle: 'METIS TRAIL',
        gpxURL: 'assets/gpx/trace-METIS2024.gpx',
        color: '#518fc9',
        outlineColor: '#264766',
        class: 'gr-metis',
        classText: 'gr-text-metis',
        classbg: 'gr-bg-metis',
        strokeWidth: 5,
        startingRace: {
            flag: 'assets/img/starting-flag.svg',
            position: {
                lat: -21.01573775513106,
                long: 55.26092593112088
            }

        },
        endingRace: {
            flag: 'assets/img/ending-flag.svg',
            position: {
                lat: -20.88506,
                long: 55.44243
            }
        }
    }
]

//-- Initialization --
function init(){
    //console.log('init');
    initSessionStorage()
    initEventsListeners()
    initRefreshingMap()
    initRaceFilteringOverlay()
}

//-- Initialization of the session data --
function initSessionStorage(){
    
    if(sessionStorage.getItem('grData')){
        currentMapMode = JSON.parse(sessionStorage.getItem('grData')).currentMapMode
        currentIdDossard = JSON.parse(sessionStorage.getItem('grData')).currentIdDossard
    } else{
        const data = JSON.stringify({ currentIdDossard: currentIdDossard, currentMapMode: currentMapMode })
        sessionStorage.setItem('grData', data);
    }
    
}
function initContentCoureur(){
    //-- Init runner card color --
    initCardRunnerColor()

    //-- Init runner card content --
    let nomCourse = document.querySelector("#gr-fiche-coureur-panel .gr-fiche-coureur-card h1");
    let nomCourse2 = document.querySelector("#gr-fiche-coureur-panel > h1");
    while (nomCourse.firstChild) {
        nomCourse.removeChild(nomCourse.lastChild);
    }
    while (nomCourse2.firstChild) {
        nomCourse2.removeChild(nomCourse2.lastChild);
    }
    let lenomcourse = dataOneRunner.info[currentIdDossard].infos.course+" ";
    let lenomcourse2 = dataOneRunner.info[currentIdDossard].infos.course+" " ;
    if(dataOneRunner.info[currentIdDossard].infos.categorie!=''&&dataOneRunner.info[currentIdDossard].infos.categorie!=null){
      lenomcourse+= dataOneRunner.info[currentIdDossard].infos.categorie ;
      lenomcourse2+= dataOneRunner.info[currentIdDossard].infos.categorie ;
    }
    let textnomcourse = document.createTextNode(lenomcourse );
    let textnomcourse2 = document.createTextNode(lenomcourse2 );
    nomCourse.appendChild(textnomcourse);
    nomCourse2.appendChild(textnomcourse2);
    let nomcoureur = document.querySelector(".gr-fiche-nom");
    Array.from(nomcoureur.children).forEach( child => child.remove())
    let spannom = document.createElement("span");
    spannom.classList.add('uk-text-uppercase')
    let spanprenom = document.createElement("span");
    spanprenom.classList.add('uk-text-capitalize')
    let textnom = document.createTextNode(dataOneRunner.info[currentIdDossard].infos.nom);
    let textprenom = document.createTextNode(dataOneRunner.info[currentIdDossard].infos.prenom);
    spannom.appendChild(textnom);
    spanprenom.appendChild(textprenom);
    nomcoureur.append(spanprenom, ' ', spannom);

    let classementcoureur = document.querySelector(".gr-fiche-classement span");
    let spanclassement = document.createElement("span");
    let textclassement = document.createTextNode("#"+dataOneRunner.info[currentIdDossard].infos.clt);
    spanclassement.appendChild(textclassement);
    classementcoureur.replaceWith(spanclassement);

    let ndossardcoureur = document.querySelector(".gr-fiche-dossard span");
    let spanndossard = document.createElement("span");
    let textndossard = document.createTextNode(dataOneRunner.info[currentIdDossard].id_dossard);
    spanndossard.appendChild(textndossard);
    ndossardcoureur.replaceWith(spanndossard);

    let etatcoureur = document.querySelector(".gr-fiche-etat span");
    let spannetat = document.createElement("span");
    let textetat = document.createTextNode(dataOneRunner.info[currentIdDossard].infos.etat);
    spannetat.appendChild(textetat);
    etatcoureur.replaceWith(spannetat);

    let lastpassagecoureur = document.querySelector(".gr-last-passage");
    while (lastpassagecoureur.firstChild) {
        lastpassagecoureur.removeChild(lastpassagecoureur.lastChild);
    }
    let spanpassage1 = document.createElement("span");
    spanpassage1.classList.add("uk-margin-small-right");

    let lastpassage = dataOneRunner.info[currentIdDossard].pointage[dataOneRunner.info[currentIdDossard].pointage.length-1];

    if(lastpassage){
        let textpassage1 = document.createTextNode(lastpassage.datePassage);
        spanpassage1.appendChild(textpassage1);
        lastpassagecoureur.appendChild(spanpassage1);

        let spanpassage2 = document.createElement("span");
        if(lastpassage.nompointage){
            let textpassage2 = document.createTextNode(lastpassage.nompointage);
            spanpassage2.appendChild(textpassage2);
            lastpassagecoureur.appendChild(spanpassage2);
        }
    } else {
        const message = document.createTextNode('Aucun résultat.')
        lastpassagecoureur.appendChild(message)
    }


    let tablePassage = document.querySelector("table.gr-fiche-coureur-table tbody");
    while (tablePassage.firstChild) {
        tablePassage.removeChild(tablePassage.lastChild);
    }
    let objtr =  document.createElement("tr");
    let objtd1 = document.createElement("td");
    let objtd2 = document.createElement("td");
    objtd2.classList.add("uk-text-center");

    const pointages = dataOneRunner.info[currentIdDossard].pointage

    if( pointages.length != 0 ){
        pointages.forEach(function(item){
            let tr1 = objtr.cloneNode(false);
            let td1 = objtd1.cloneNode(false);
            let texttd1 = document.createTextNode("");
            if(item.nompointage){
                texttd1 = document.createTextNode(item.nompointage);
            }
            td1.appendChild(texttd1);
            tr1.appendChild(td1);
            let td2 = objtd2.cloneNode(false);
            let texttd2 = document.createTextNode(item.tempscourse);
            td2.appendChild(texttd2);
            tr1.appendChild(td2);
            let td3 = objtd2.cloneNode(false);
            let texttd3 = document.createTextNode(item.clt);
            td3.appendChild(texttd3);
            tr1.appendChild(td3);
            tablePassage.appendChild(tr1);
        });
    } else {
        const message = document.createTextNode('Aucun résultat.')
        tablePassage.appendChild(objtr)
        objtr.appendChild(objtd1)
        objtd1.appendChild(message)
        objtd1.setAttribute('colspan', '3')
        objtd1.classList.add('uk-text-center')
    }

}

function initCardRunnerColor(){
    const currentRace = dataOneRunner.info[currentIdDossard].infos.course
    const borderElement = document.querySelector('#gr-fiche-coureur-panel .gr-suivi-coureur-border')
    const backgroundElement = document.querySelector('#gr-fiche-coureur-panel .gr-suivi-coureur-background')

    raceConfigs.forEach( race => {
        const currentRaceSelector = race.idConfig.split(' ').join('-').toLowerCase()
        const borderClass = 'gr-suivi-coureur-border-left-'+currentRaceSelector
        const backgroundClass = 'gr-suivi-coureur-background-'+currentRaceSelector

        currentRace == race.idConfig ? borderElement.classList.add(borderClass) : borderElement.classList.remove(borderClass)
        currentRace == race.idConfig ? backgroundElement.classList.add(backgroundClass) : backgroundElement.classList.remove(backgroundClass)

    })
}

function initPanel(){
    if(currentMapMode == 'trackingMultipleRunner'){
        //-- Hide panel fiche coureur --
        document.querySelector("#gr-fiche-coureur-panel-container").style.display = "none"
        document.querySelector(".li-infos-coureur").classList.add('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile span.openInfos").classList.remove('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile svg.openInfos").classList.remove('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile span.closeInfos").classList.add('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile svg.closeInfos").classList.add('uk-hidden')
        //-- Show suivi coureur panel (celui avec le dégradé bleu) --
        document.querySelector("#gr-suivi-coureur-container").style.display = "flex"
        document.querySelector("#gr-section-marla").style.display = "block"
    }
    if(currentMapMode == 'trackingOneRunner'){
        initContentCoureur();
        //-- Show panel fiche coureur --
        document.querySelector("#gr-fiche-coureur-panel-container").style.display = "block"
        document.querySelector(".li-infos-coureur").classList.remove('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile span.openInfos").classList.add('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile svg.openInfos").classList.add('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile span.closeInfos").classList.remove('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile svg.closeInfos").classList.remove('uk-hidden')
        //-- Hide suivi coureur panel (celui avec le dégradé bleu) --
        document.querySelector("#gr-suivi-coureur-container").style.display = "none"
        document.querySelector("#gr-section-marla").style.display = "none"
    }
}

//-- Initialization of the events listeners --
function initEventsListeners(){
  document.querySelector('.gr-suivi-coureur-cta-brand').addEventListener('click',()=>handleClickHome());
    document.querySelector("#gr-suivi-coureur-toggle-map").addEventListener('click', () => handleClickToogleMap())
    document.querySelector("#gr-suivi-coureur-ranking-see-map-button").addEventListener('click', () => handleClickToogleMap())
    document.querySelector("#gr-fiche-coureur-toggle-panel").addEventListener('click', (e) => handleClickToogleMapRunnerDesktop(e, 'desktop'))
    document.querySelector(".gr-fiche-coureur-toggle-panel-mobile").addEventListener('click', (e) => handleClickToogleMapRunnerDesktop(e, 'mobile'))
    document.querySelector("#gr2022-infos-coureur-button-mobile").addEventListener('click',  (e) => handleClickToogleMapRunnerDesktop(e, 'mobile'))
    document.querySelector("#gr2022-filter-button-mobile").addEventListener('click', () => {
        document.querySelector("#gr-race-filtering-overlay").classList.toggle('open-filter-mobile')
    })

    //----- CHANGE THE MAP ON TRACKING MULTIPLE RUNNERS -----
    //-- Left logo on the header --
    document.querySelector(".gr-nav-container .uk-navbar-left").addEventListener('click',(e) => handleChangeMap(e) )
    //-- Button "suivre la course" Desktop --
    document.querySelector("#button-suivre-la-course").addEventListener('click',(e) => handleChangeMap(e) )
    //-- Button "suivre la course" Mobile --
    document.querySelector("#button-suivre-la-course-mobile").addEventListener('click',(e) => handleChangeMap(e) )
}


//-- Initialization of the map --
function initMap(){
    let innerWidthCondition = window.innerWidth >= 1440
    let zoominitial = 10;
    if(innerWidthCondition){
      zoominitial = 11;
    }
    //-- Prevent tiles loading thanks to an area --
    var southWest = L.latLng(-21.389256, 55.216414),
        northEast = L.latLng(-20.871859, 55.836273),
        mybounds = L.latLngBounds(southWest, northEast);

    //-- Init the map --
    map = L.map('gr-suivi-coureur-map', {
        renderer: L.svg(),
        center: mapCenter,
        zoom: zoominitial,
        gestureHandling: true,
        gestureHandlingOptions: {
            duration: 3000 //3 secs
        }
    })

    //-- Load the map from tiles --
    // TODO Change the url to prod
    // https://gr2022.digital1to1.fr/osmfr/{z}_{x}_{y}.png - DEV
    // https://grandraid.sfr.re/osmfr/{z}_{x}_{y}.png - PROD
    L.tileLayer(baseURL+'/osmfr/v2/osm_tiles/webp/{z}_{x}_{y}.webp', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        minZoom: 10,
        maxZoom: 18,
        bounds: mybounds
    }).addTo(map)

    //-- Change the position of the zoom control --
    map.zoomControl.setPosition('topright')

    //-- Tracing race depending of the current map mode --
    tracingRaces()

    //-- Place on the map starting flag and ending flag --
    placeStartingEndingFlags()

    mapIsInit = true

    window.addEventListener('wheel', () => adjusingtMessageOverlayMap() )
    window.addEventListener('touchmove', () => {
        adjusingtMessageOverlayMap()
    } )

    adjustingAlignementInfoLabel()

    window.addEventListener('resize', () => adjustingAlignementInfoLabel())

    debugProblemZoomButtonOnMobile()
    fixProblemMapHeight()





    // Ajout des points GPS sur la carte avec icône personnalisée et popup formatée
    pointsGPS.forEach(point => {

        const iconClassName = point.customIcon;
        const customIcon = L.icon({
            iconUrl: `assets/img/${iconClassName}`, // Le chemin vers l'icône dépendra du nom de className
            iconSize: [38, 38], // Taille de l'icône
            iconAnchor: [19, 38], // Point de l'icône qui correspond à la position du marqueur
            popupAnchor: [0, -38] // Point de l'icône où la popup sera ancrée
        });

        const marker = L.marker([point.lat, point.lng], { icon: customIcon });

        // Structure HTML personnalisée pour le contenu de la popup
        const popupContent = `<div class="leaflet-popup-content" style="width: 222px;">
                                <div class="gr-popup-ravitos">${point.popupText}</div>
                              </div>`;

        if (point.popupText) {
            marker.bindPopup(popupContent);
        }

        // Ajouter le marqueur à l'objet markersByClass selon la classe
        if (!markersByClass[point.className]) {
            markersByClass[point.className] = [];
        }
        markersByClass[point.className].push(marker);
    });

    // Écouter le changement d'état de toutes les checkbox avec la classe `togglePoints`
    document.querySelectorAll('.togglePoints').forEach(checkbox => {
        checkbox.addEventListener('change', function() {
            const checked = this.checked;
            const className = this.getAttribute('data-class'); // Récupère la classe associée

            if (markersByClass[className]) {
                markersByClass[className].forEach(marker => {
                    if (checked) {
                        marker.addTo(map);
                    } else {
                        map.removeLayer(marker);
                    }
                });
            }
        });
    });





}

function initRaceFilteringOverlay(){
    const checkboxs = document.querySelectorAll("#gr-race-filtering-overlay input[type='checkbox']")

    //-- Init the checkbox to the current session data --
    const sessionData = JSON.parse(sessionStorage.getItem('grData'))
    if(Object.prototype.hasOwnProperty.call(sessionData,'raceFiltering')){
        checkboxs.forEach( checkbox => {
            const courseId = checkbox.getAttribute('data-race')
            checkbox.checked =  sessionData.raceFiltering[courseId]

            //-- Init hightlighting filter --
            if(!checkbox.checked) checkbox.closest('li').classList.toggle('active')
        })
    }

    //-- Add event on each checkboxs --
    checkboxs.forEach( checkbox => checkbox.addEventListener('change', (e) => handleChange(e)) )

    //-- Event Handler --
    const handleChange = (e) => {
        const racesToShow = []
        const sessionData = { raceFiltering: {} }

        //-- Change the higthlighting --
        e.currentTarget.closest("li").classList.toggle('active')

        checkboxs.forEach( checkbox => {
            const raceId = checkbox.getAttribute('data-race')

            if(checkbox.checked){
                map.addLayer(markersCluster.subGroups[raceId])
                racesToShow.push(raceId)
            } else {
                map.removeLayer(markersCluster.subGroups[raceId])
            }

            //-- Stock session data in local var --
            sessionData.raceFiltering[raceId] = checkbox.checked
        })

        //-- Set the current race filtering in session storage --
        setSessionStorage(sessionData)

        //-- Show race depending the filtering --
        showRaces(racesToShow)
    }

    //-- Hide race filtering overlay on tracking one runner --
    if(currentMapMode == "trackingOneRunner") hideRaceFilteringOverlay()
}

function hideRaceFilteringOverlay(){
    document.querySelector("#gr-race-filtering-overlay").style.display = "none"
}

function showRaceFilteringOverlay(){
    document.querySelector("#gr-race-filtering-overlay").style.display = "block"
}

function adjusingtMessageOverlayMap(){
    let innerWidthCondition, container, containerIsOpen, rightCenterClass
    const mapOverlayMessageDesktop = document.querySelector(".leaflet-gesture-handling-scroll-warning")
    const mapOverlayMessageMobile = document.querySelector(".leaflet-gesture-handling-touch-warning")
    const mapOverlayMessage = mapOverlayMessageDesktop ? mapOverlayMessageDesktop : mapOverlayMessageMobile
    const oneRunnerRightCenterClass = "gr-fiche-coureur-overlay-map-message-right-center"
    const multipleRunnerRightCenterClass = "gr-fiche-coureur-overlay-map-message-multiple-runner-right-center"
    const centerClass = "gr-fiche-coureur-overlay-map-message-center"

    //-- Set local vars depending of the current map mode --
    if(currentMapMode == 'trackingMultipleRunner'){
        innerWidthCondition = window.innerWidth > 640
        container = document.querySelector("#gr-suivi-coureur-container")
        containerIsOpen = container.classList.contains("gr-suivi-coureur-map-close")
        rightCenterClass = multipleRunnerRightCenterClass
    } else {
        innerWidthCondition = window.innerWidth >= 960
        container = document.querySelector("#gr-fiche-coureur-panel-container")
        containerIsOpen = container.classList.contains("gr-fiche-coureur-open-panel")
        rightCenterClass = oneRunnerRightCenterClass
    }


    if(mapOverlayMessage){
        //-- Clear class before add new --
        mapOverlayMessage.classList.remove(centerClass)
        mapOverlayMessage.classList.remove(rightCenterClass)
        mapOverlayMessage.classList.remove(multipleRunnerRightCenterClass)

        //-- Align map overlay message --
        if(innerWidthCondition) {
            containerIsOpen ? mapOverlayMessage.classList.add(rightCenterClass) : mapOverlayMessage.classList.add(centerClass)
        } else if(currentMapMode == 'trackingMultipleRunner') {
            mapOverlayMessage.classList.add(centerClass)
        }
    }
}

function adjustingAlignementInfoLabel(){
    let container, containerIsOpen, innerWidthCondition, rightCenterClass
    const infoLabel = document.querySelector(".gr-fiche-coureur-info-label")
    const rightCenterClassMultipleRunner = "gr-fiche-coureur-info-label-right-center-multiple-runner"
    const rightCenterClassOneRunner = "gr-fiche-coureur-info-label-right-center-one-runner"

    //-- Set local vars depending the current map mode --
    if(currentMapMode == 'trackingMultipleRunner'){
        container = document.querySelector("#gr-suivi-coureur-container")
        containerIsOpen = container.classList.contains('gr-suivi-coureur-map-close')
        innerWidthCondition = window.innerWidth > 640
        rightCenterClass = rightCenterClassMultipleRunner
    } else {
        container = document.querySelector("#gr-fiche-coureur-panel-container")
        containerIsOpen = container.classList.contains('gr-fiche-coureur-open-panel')
        innerWidthCondition = window.innerWidth >= 960
        rightCenterClass = rightCenterClassOneRunner
    }

    //-- Clear class before add new --
    infoLabel.classList.remove(rightCenterClassOneRunner)
    infoLabel.classList.remove(rightCenterClassMultipleRunner)

    //-- Align info label : "Position en temps réel" --
    if(innerWidthCondition && containerIsOpen) infoLabel.classList.add(rightCenterClass)
}

function adjustingMapCenter() {
    let panel, panelOpenClass, currentRunnerLatLng, breakpointCondition
    //-- If is the first init of the map then choose a random runner --
    if (randomRunnerLatLng == null){
        /*const data = Object.values(dataMultipleRunner.info)
        const min = 0
        const max = data.length
        const randomIndexOfRunner = Math.floor(Math.random() * (max - min) + min)
        //-- Save the random runner to a global var --
        randomRunnerLatLng = L.latLng(data[randomIndexOfRunner].dernierePosition.lat, data[randomIndexOfRunner].dernierePosition.long)
        */
        randomRunnerLatLng = L.latLng(mapCenter[0],mapCenter[1]);
    }

    //-- Set local vars depending of the current map mode --
    if (currentMapMode == 'trackingMultipleRunner') {
        panel = document.querySelector("#gr-suivi-coureur-container")
        panelOpenClass = "gr-suivi-coureur-map-close"
        currentRunnerLatLng = randomRunnerLatLng
        breakpointCondition = window.innerWidth > 640
    } else {
        panel = document.querySelector("#gr-fiche-coureur-panel-container")
        panelOpenClass = "gr-fiche-coureur-open-panel"
        currentRunnerLatLng = L.latLng(dataOneRunner.info[currentIdDossard].dernierePosition.lat, dataOneRunner.info[currentIdDossard].dernierePosition.long)
        breakpointCondition = window.innerWidth >= 960
    }

    // -- If the left panel is open, then center the map on the right remaining space --
    if (breakpointCondition && panel.classList.contains(panelOpenClass)) {
        const rightOffset = (0.5 * window.innerWidth) - (0.5 * (window.innerWidth - panel.offsetWidth))
        //-- Convert lat long to pixel --
        const randomRunnerPoint = map.project(currentRunnerLatLng, map.getZoom())
        //-- Add the right offset --
        randomRunnerPoint.x = randomRunnerPoint.x - rightOffset
        //-- Convert the new point to latlong --
        currentRunnerLatLng = map.unproject(randomRunnerPoint, map.getZoom())
    }

    //-- Move the map to the random runner the lat lng coordinate --

    //console.log('fly?');
    if(!flyIsInit) {
      //console.log('fly');
      map.flyTo(currentRunnerLatLng)
      flyIsInit = true;
    }
}

function debugProblemZoomButtonOnMobile(){
    const zoomButtons = document.querySelectorAll('.leaflet-control-zoom-in, .leaflet-control-zoom-out')

    zoomButtons.forEach( button => {
        button.addEventListener('click', () =>  window.scrollTo(0,0) )
    })
}



function setSessionStorage(obj){
    const currentSessionData = JSON.parse(sessionStorage.getItem('grData'))
    const data = Object.assign(currentSessionData, obj)
    sessionStorage.setItem('grData', JSON.stringify(data));
}

//-- Use to retrieve data runners each "refreshMapInterval" --
async function initRefreshingMap(){
    if(currentMapMode == 'trackingMultipleRunner') refreshingDataOnTrackingMultipleRunner()
    if(currentMapMode == 'trackingOneRunner') refreshingDataOnTrackingOneRunner()

}

function refreshingDataOnTrackingMultipleRunner(){
    //const isFirstInitData = dataMultipleRunner === null
    let apiRoute = multipleRunnerApiRoute
    let formdata = null

    //-- If is not the first initialization of data runners --
    /*if(!isFirstInitData) {
        //-- Change the api route if is not the first initalization of the data--
        apiRoute = updatedMultipleRunnerApiRoute

        //-- Pass in param of the request a lastmaj timestamp to retrieve only the updated data --
        formdata = new FormData();
        formdata.append("lastDemande", dataMultipleRunner.lastmaj)
        // formdata.append("lastDemande", 1658254140); // TEST
    }*/

    fetch(baseURL+apiRoute, {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    })
        .then( response => { return response.json() })
        .then( data => {
            if(data.status == 'succes'){
                //-- Refreshing data of runners  --
                dataMultipleRunner = data
                //isFirstInitData ? dataMultipleRunner = data : updateDataMultipleRunners(data)
                if(dataMultipleRunner.afficheClassement){
                  const afficherobj = document.querySelectorAll(".showclassement")
                  //console.log("afficherobj:",afficherobj);
                  afficherobj.forEach(obj => obj.classList.remove("uk-hidden"))
                }
                else{
                  const afficherobj = document.querySelectorAll(".showclassement")
                  afficherobj.forEach(obj => obj.classList.add("uk-hidden"))
                }
                initPanel()
                if(!mapIsInit){
                    initMap()
                }
                refreshMakersOfRunners()

                adjustingMapCenter()

                //refSetTimeoutMultipleRunner = setTimeout(() => refreshingDataOnTrackingMultipleRunner(), refreshMapInterval)
            } else {
                //refSetTimeoutMultipleRunner = setTimeout(() => refreshingDataOnTrackingMultipleRunner(), refreshMapInterval)
            }
        })
        //.catch(() => refSetTimeoutMultipleRunner = setTimeout(() => refreshingDataOnTrackingMultipleRunner(), refreshMapInterval))

}

function refreshingDataOnTrackingOneRunner(){
    const isFirstInitData = dataMultipleRunner === null
    const formdata = new FormData();
    formdata.append("idDossard", currentIdDossard)

    if(isFirstInitData) {
        //-- Retrieve data of multiple runners --
        fetch(baseURL + multipleRunnerApiRoute)
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.status == 'succes') dataMultipleRunner = data
            })
    }

    let fileOneRunner = "d"+currentIdDossard+".json"
    //-- Retrieve data of one runner --
    fetch(baseURL+oneRunnerApiRoute+fileOneRunner, {
        method: 'GET',
        //body: formdata,
        redirect: 'follow'
    })
        .then( response => { return response.json() })
        .then( data => {
            if(data.status == 'succes'){
                dataOneRunner = data
                if(dataOneRunner.afficheClassement){
                  const afficherobj = document.querySelectorAll(".showclassement")
                  //console.log("afficherobj:",afficherobj);
                  afficherobj.forEach(obj => obj.classList.remove("uk-hidden"))
                }
                else{
                  const afficherobj = document.querySelectorAll(".showclassement")
                  afficherobj.forEach(obj => obj.classList.add("uk-hidden"))
                }
                initPanel()
                if(!mapIsInit) initMap()

                refreshMakersOfRunners()

                adjustingMapCenter()


                //-- Tracing of the current route of the runner --
                const currentRaceConfig = getConfigByRace(dataOneRunner.info[currentIdDossard].infos.course)
                tracingCurrentRouteOfRunner(currentRaceConfig)

                //refSetTimeoutOneRunner = setTimeout(() => refreshingDataOnTrackingOneRunner(), refreshMapInterval)
            } else {
                //refSetTimeoutOneRunner = setTimeout(() => refreshingDataOnTrackingOneRunner(), refreshMapInterval)
            }
        })
        //.catch(() => refSetTimeoutOneRunner = setTimeout(() => refreshingDataOnTrackingOneRunner(), refreshMapInterval))

}

/*function updateDataMultipleRunners(updatedData){
    //-- Update each data have change --
    Object.values(updatedData.info).forEach( data => {
        dataMultipleRunner.info[data.id_dossard].dernierePosition = data.dernierePosition
        dataMultipleRunner.info[data.id_dossard].infos = data.infos
    })

    //-- Update the lastmaj timestamp --
    dataMultipleRunner.lastmaj = updatedData.lastmaj
}*/

function refreshLayersMap(){
    //----- HIDE ELEMENTS OF THE MAP -----//
    //-- Hide all starting flags --
    startingFlagLayers.forEach( layer => layer._icon.style.display = 'none')

    //----- SHOW ELEMENTS OF THE MAP DEPENDING THE CURRENT MAP -----//
    if(currentMapMode == "trackingMultipleRunner"){
        //-- Show tracing race depending the session data --
        const sessionData = JSON.parse(sessionStorage.getItem('grData'))

        if(Object.prototype.hasOwnProperty.call(sessionData,'raceFiltering')) {
            const racesToShow = Object.entries(sessionData.raceFiltering).map( filter => {
                const [courseId, bool] = filter
                if(bool) return courseId
            })
            showRaces(racesToShow)
        } else{
            //-- Show all tracing races --
            const racesToShow = raceConfigs.map( race => race.idConfig)
            showRaces(racesToShow)
        }

        //-- Show all starting flags --
        startingFlagLayers.forEach( layer => layer._icon.style.display = 'block' )

        showRaceFilteringOverlay()
    }

    if(currentMapMode == "trackingOneRunner") {
        //-- Retrieve the race config of the current runner --
        const currentRace = dataMultipleRunner.info[currentIdDossard].infos.course
        const raceConfig = getConfigByRace(currentRace)

        //-- Show tracing race of the current runner --
        showRaces([currentRace])

        //-- Show the starting flag of the current runner --
        startingFlagLayers.forEach( layer => {
            const latLngLayer = layer._latlng
            const latLngRaceConfig = raceConfig.startingRace.position
            if(latLngLayer.lat === latLngRaceConfig.lat && latLngLayer.lng === latLngRaceConfig.long) layer._icon.style.display = 'block'
        })

        hideRaceFilteringOverlay()
    }
}

//-- Trace race depending the current map mode --
async function tracingRaces(){
    //-- Tracing all of race --
    if( currentMapMode == "trackingMultipleRunner"){
        for (const config of raceConfigs) {
            await tracingOfRace(config.idConfig, config.gpxURL, config.color, config.outlineColor, config.strokeWidth)
        }

        //-- Show the race depending the current filtering race --
        const sessionData = JSON.parse(sessionStorage.getItem('grData'))

        if(Object.prototype.hasOwnProperty.call(sessionData,'raceFiltering')){
            const racesToShow = Object.entries(sessionData.raceFiltering).map( filter => {
                const [courseId, bool] = filter
                if(bool) return courseId
            })

            showRaces(racesToShow)
        }

    }

    //-- Tracing race of the current runner --
    if( currentMapMode == "trackingOneRunner") {
        const currentRaceConfig = getConfigByRace(dataOneRunner.info[currentIdDossard].infos.course)

        for (const config of raceConfigs) {

            const strokeColor = config.idConfig == currentRaceConfig.idConfig ? currentRaceConfig.color : "rgba(0,0,0,0)"
            const outlineColor = config.idConfig == currentRaceConfig.idConfig ? currentRaceConfig.outlineColor : "rgba(0,0,0,0)"
            await tracingOfRace(config.idConfig, config.gpxURL, strokeColor, outlineColor, config.strokeWidth)
        }

        //-- Tracing of the current route of a runner --
        tracingCurrentRouteOfRunner(currentRaceConfig)
    }
}

//-- Trace a race on the map --
async function tracingOfRace(idConfig, gpxURL, strokeColor, outlineColor, strokeWidth){
    const gpxJSON = await convertGpxToJSON(gpxURL)

    //-- Create an object of the layer race --
    const layerRace = {
        idConfig: idConfig,
        layerOutline: L.geoJson(gpxJSON, { color: outlineColor, weight: strokeWidth + 3}).addTo(map),
        layer: L.geoJson(gpxJSON, { color: strokeColor, weight: strokeWidth}).addTo(map)
    }

    //-- Put the layer in an array --
    return new Promise(resolve => resolve(racesTracingLayers.push(layerRace)))
}

//-- Tracing of the current route of a runner --
function tracingCurrentRouteOfRunner(currentRaceConfig){
    removeTracingCurrentRouteOfRunner()
    const raceTracing = racesTracingLayers.filter( raceTracing => { return raceTracing.idConfig == currentRaceConfig.idConfig })[0].layer
    const raceTracingLatLng = Object.values(raceTracing._layers)[0]._latlngs
    let currentRunnerPoint = dataOneRunner.info[currentIdDossard].dernierePosition
    currentRunnerPoint = L.latLng(currentRunnerPoint.lat, currentRunnerPoint.long)
    const distancesPointMarker = []

    for (const index in raceTracingLatLng) {
        const point = L.latLng(raceTracingLatLng[index].lat, raceTracingLatLng[index].lng);
        const distance = point.distanceTo(currentRunnerPoint)
        distancesPointMarker.push(distance)
    }

    const smallestDistance = Math.min(...distancesPointMarker)
    const indexOfSmallestDistance = distancesPointMarker.indexOf(smallestDistance)
    const currentRouteOfRunner = raceTracingLatLng.slice(0, indexOfSmallestDistance+1)

    //-- Tracing of the current route of the runner --
    currentRouteOfRunner.push(currentRunnerPoint)
    tracingOfCurrentRouteRunner = L.polyline(currentRouteOfRunner, {color: 'black', weight: currentRaceConfig.strokeWidth}).addTo(map);

}

function removeTracingCurrentRouteOfRunner(){
    //-- Remove the current route of the runner --//
    if(tracingOfCurrentRouteRunner != null){
        map.removeLayer(tracingOfCurrentRouteRunner)
        tracingOfCurrentRouteRunner = null
    }
}

//-- Take an array of races to show in param and hide all races not in the array --
function showRaces(racesToShow){
    racesTracingLayers.forEach( raceLayer => {
        if(racesToShow.includes(raceLayer.idConfig)){
            if(raceLayer.layer._map == null){
                map.addLayer(raceLayer.layerOutline)
                map.addLayer(raceLayer.layer)
            }
        } else {
            if(raceLayer.layer._map != null){
                map.removeLayer(raceLayer.layer)
                map.removeLayer(raceLayer.layerOutline)
            }
        }
    })
}

//-- Put the starting and ending flag on the map to each race --
function placeStartingEndingFlags(){

    if(currentMapMode == "trackingMultipleRunner"){
        raceConfigs.forEach( config => {
            const startingFlagIcon = L.icon({ iconUrl: config.startingRace.flag, iconSize: [37,37], className: 'gr-flags-marker'  })
            startingFlagLayers.push(L.marker([config.startingRace.position.lat, config.startingRace.position.long], { icon: startingFlagIcon }).addTo(map))
        })
    }
    if(currentMapMode == "trackingOneRunner") {
        const config = getConfigByRace(dataOneRunner.info[currentIdDossard].infos.course)
        const startingFlagIcon = L.icon({ iconUrl: config.startingRace.flag, iconSize: [37,37], className: 'gr-flags-marker' })
        startingFlagLayers.push(L.marker([config.startingRace.position.lat, config.startingRace.position.long], { icon: startingFlagIcon }).addTo(map))
    }

    //-- Place the ending flag --
    const endingFlagIcon = L.icon({ iconUrl: raceConfigs[0].endingRace.flag, iconSize: [37,37], className: 'gr-flags-marker' })
    L.marker([raceConfigs[0].endingRace.position.lat, raceConfigs[0].endingRace.position.long], { icon: endingFlagIcon }).addTo(map)
}

//-- Updating markersCluster of runners --
function refreshMakersOfRunners(){
  //console.log('refreshMakersOfRunners')
    let markerIconTemplate='<svg width="40" height="60" viewBox="0 0 40 60" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.47717 3.65257C16.9092 -0.417906 27.3704 -0.0123606 34.3595 6.32616C45.8427 17.9218 29.5498 47.3463 21.4785 57.8454C21.1177 58.3111 20.5616 58.5814 19.9604 58.5814H19.9303C19.269 58.5814 18.6527 58.236 18.292 57.6802C11.6035 46.8356 -7.66546 17.396 5.6214 5.50005" fill="{mapIconColor}"/><path d="M8.47717 3.65257C16.9092 -0.417906 27.3704 -0.0123606 34.3595 6.32616C45.8427 17.9218 29.5498 47.3463 21.4785 57.8454C21.1177 58.3111 20.5616 58.5814 19.9604 58.5814H19.9303C19.269 58.5814 18.6527 58.236 18.292 57.6802C11.6035 46.8356 -7.66546 17.396 5.6214 5.50005L7 4.5L8.47717 3.65257Z" stroke="white" stroke-width="2" stroke-miterlimit="10"/><path d="M27.2359 18.523C27.2359 22.6536 23.8841 26.0031 19.7508 26.0031C15.6174 26.0031 12.2656 22.6536 12.2656 18.523C12.2656 14.3925 15.6174 11.043 19.7508 11.043C23.8841 11.043 27.2359 14.3925 27.2359 18.523Z" fill="white"/></svg>';

    //-- Remove markersCluster before update --
    if(markersCluster.parentGroup != null) markersCluster.parentGroup.removeFrom(map);

    //-- Create a group of runners position markers --
    markersCluster.parentGroup = L.markerClusterGroup()

    //-- Create sub groups of markers and set her parent --
    for (const key in markersCluster.subGroups) {
        markersCluster.subGroups[key] = L.featureGroup.subGroup(markersCluster.parentGroup).addTo(map);
    }

    const data = (currentMapMode == "trackingMultipleRunner" ? Object.values(dataMultipleRunner.info) : Object.values(dataOneRunner.info))
  //console.log('runners:',data)
    for(var z=0;z<data.length;z++){
        //console.log('z:',z)
      let runner=data[z];
        //console.log('runner:',runner)
      if('lat' in runner.dernierePosition && 'long' in runner.dernierePosition){

      const raceConfig = getConfigByRace(runner.infos.course)
      let iconSettings = {
          mapIconColor: raceConfig.color
      }
      //console.log('iconSettings:',iconSettings)
      //-- Create a icon marker depending of the race --
      const markerIcon = L.divIcon({
          html:L.Util.template(markerIconTemplate, iconSettings),
          iconSize: [25, 40],
          iconAnchor: [15, 50],
          popupAnchor: [120, 27],
          className: 'gr-runners-marker',
      })
      //console.log('runner.dernierePosition:',runner.dernierePosition)

      const marker = L.marker( [runner.dernierePosition.lat, runner.dernierePosition.long], { icon: markerIcon} )
      marker.on('click', (event) => handlePopupMarker(event, raceConfig.class, raceConfig.classText, raceConfig.classbg, raceConfig.markerPopupTitle, runner.id_dossard, runner.infos.prenom, runner.infos.nom, runner.infos.clt,runner.infos.etat))

      markersCluster.subGroups[runner.infos.course].addLayer(marker)
      }
    }
    /*data.forEach( runner => {

        const raceConfig = getConfigByRace(runner.infos.course)
        let iconSettings = {
            mapIconColor: raceConfig.color
        }
        //-- Create a icon marker depending of the race --
        const markerIcon = L.divIcon({
            html:L.Util.template(markerIconTemplate, iconSettings),
            iconSize: [25, 40],
            iconAnchor: [15, 50],
            popupAnchor: [120, 27],
            className: 'gr-runners-marker',
        })


        const marker = L.marker( [runner.dernierePosition.lat, runner.dernierePosition.long], { icon: markerIcon} )
        marker.on('click', (event) => handlePopupMarker(event, raceConfig.class, raceConfig.classText, raceConfig.classbg, raceConfig.markerPopupTitle, runner.id_dossard, runner.infos.prenom, runner.infos.nom, runner.infos.clt,runner.infos.etat))

        markersCluster.subGroups[runner.infos.course].addLayer(marker)
    })*/

    const sessionData = JSON.parse(sessionStorage.getItem('grData'))

    if(Object.prototype.hasOwnProperty.call(sessionData,'raceFiltering') && currentMapMode == "trackingMultipleRunner"){
        for (const filter in sessionData.raceFiltering) {
            if(!sessionData.raceFiltering[filter]) map.removeLayer(markersCluster.subGroups[filter])
        }
    }

    //-- Add the parent group to the map --
    markersCluster.parentGroup.addTo(map)
}



function handlePopupMarker(event, className, classText, classbg, markerPopupTitle, id_dossard, prenom, nom, clt,etat){
    let br = document.createElement("br");
    let popupmarker = document.createElement("p");
    popupmarker.classList.add("gr-suivi-coureur-marker-popup-content");
    popupmarker.classList.add(className);
    let span1=document.createElement("span");
    span1.classList.add(classText);
    let textNode1=document.createTextNode(markerPopupTitle);
    span1.appendChild(textNode1);
    popupmarker.appendChild(span1);
    popupmarker.appendChild(br.cloneNode(false));
    let textNode=document.createTextNode("N° dossard : "+id_dossard);
    popupmarker.appendChild(textNode);
    popupmarker.appendChild(br.cloneNode(false));

    if(prenom!=''){
      let span2=document.createElement("span");
      span2.classList.add("gr-suivi-coureur-marker-popup-runner-firstName", "uk-text-capitalize");
      let textNode2=document.createTextNode("Prénom : "+prenom);
      span2.appendChild(textNode2);
      popupmarker.appendChild(span2);
      popupmarker.appendChild(br.cloneNode(false));
    }

    let textNode3=document.createTextNode(" ");
    popupmarker.appendChild(textNode3);
    let span4=document.createElement("span");
    //span4.classList.add("gr-suivi-coureur-marker-popup-runner-name");
    let textNode4=document.createTextNode("Nom : ");
    span4.appendChild(textNode4);
    popupmarker.appendChild(span4);
    let span4b=document.createElement("span");
    span4b.classList.add("uk-text-uppercase");
    let textNode4b=document.createTextNode(nom);
    span4b.appendChild(textNode4b);
    popupmarker.appendChild(span4b);
    popupmarker.appendChild(br.cloneNode(false));
    let span4c=document.createElement("span");
    let textNode4c=document.createTextNode("Etat : ");
    span4c.appendChild(textNode4c);
    popupmarker.appendChild(span4c);
    let textNode4d=document.createTextNode(etat);
    popupmarker.appendChild(textNode4d);
    popupmarker.appendChild(br.cloneNode(false));

    let span5=document.createElement("span");
    //span5.classList.add("gr-suivi-coureur-marker-popup-runner-name");
    let textNode5=document.createTextNode("Classement : ");
    span5.appendChild(textNode5);
    popupmarker.appendChild(span5);

    let textNode6=document.createTextNode(( clt > 0 ? clt : '-' ));
    popupmarker.appendChild(textNode6);

    let span7=document.createElement("sup");
    let textNode7=document.createTextNode(( clt == 1 ? 'er' : 'ème' ));
    span7.appendChild(textNode7);
    popupmarker.appendChild(span7);

    let span8=document.createElement("span");
    span8.classList.add("gr-suivi-coureur-marker-popup-arrow");
    span8.classList.add(classbg);
    popupmarker.appendChild(span8);

    event.target.bindPopup(popupmarker,{closeButton: false, minWidth: 170, minHeight: 90})
    event.target.openPopup()
    event.target.on('popupclose', () => event.target.unbindPopup())
}

//-- Return a promise with the JSON of the GPX file --
async function convertGpxToJSON(gpxURL){
    const response = await fetch(gpxURL)
    const gpxDOM = new DOMParser().parseFromString(await response.text(), "text/xml")
    return toGeoJSON.gpx(gpxDOM)
}

//-- Get the configuration of a race thanks to the idConfig --
function getConfigByRace(idConfig){
    let config = null

    raceConfigs.forEach( raceConfig => {
        if(raceConfig.idConfig == idConfig) config = raceConfig
    })

    return config
}

function handleChangeMap(e){
    const currentElement = e.currentTarget
    //-- Get some data --
    currentMapMode = currentElement.getAttribute('data-current-map')
    currentIdDossard = currentElement.hasAttribute('data-id-dossard') ? currentElement.getAttribute('data-id-dossard') : null

    //-- Update session storage --
    setSessionStorage({ currentMapMode: currentMapMode, currentIdDossard: currentIdDossard })

    //-- Change the panel depending of the currentMapMode --
    if(currentMapMode == 'trackingMultipleRunner') {
        clearTimeout(refSetTimeoutOneRunner)
        removeTracingCurrentRouteOfRunner()
        refreshingDataOnTrackingMultipleRunner()
        document.querySelector("li.li-suivre-la-course.li-suivre-la-course-mobile").classList.add('uk-hidden');
    }
    if(currentMapMode == 'trackingOneRunner') {
        clearTimeout(refSetTimeoutMultipleRunner)
        clearTimeout(refSetTimeoutOneRunner)
        refreshingDataOnTrackingOneRunner()
        document.querySelector("li.li-suivre-la-course.li-suivre-la-course-mobile").classList.remove('uk-hidden');
    }

    //-- Refresh all layers of the map --
    refreshLayersMap()

    //-- Remove suggestions of the search bar --
    const suggestions = document.querySelectorAll("#gr-2022-header-list-suggestion li")
    suggestions.forEach( suggestion => suggestion.remove())

    //-- Clear the input of the search bar --
    document.querySelector(".gr-suivi-coureur-header form input").value = ""

    //-- Fermeture du panel ranking si ouvert
    const panel = document.querySelector("#gr-suivi-coureur-panel-ranking")
    const openClass = "gr-suivi-coureur-panel-ranking-open"
    const closeClass = "gr-suivi-coureur-panel-ranking-close"

    if(panel.classList.contains(openClass)){
        //-- Close the ranking panel --
        panel.classList.replace(openClass, closeClass)
    }

    adjusingtMessageOverlayMap()

    adjustingAlignementInfoLabel()
    fixProblemMapHeight()
}
function handleClickHome(){
  const panel = document.querySelector("#gr-suivi-coureur-panel-ranking")
  const openClass = "gr-suivi-coureur-panel-ranking-open"
  const closeClass = "gr-suivi-coureur-panel-ranking-close"

  if(panel.classList.contains(openClass)){
      //-- Close the ranking panel --
      panel.classList.replace(openClass, closeClass)
  }
}
//-- Use to open and close the Map (Multiple Runner)--
function handleClickToogleMap(){
    const container = document.querySelector("#gr-suivi-coureur-container")
    const toogle = document.querySelector("#gr-suivi-coureur-text-toggle-map")
    const closeMap = "gr-suivi-coureur-map-close"
    const openMap = "gr-suivi-coureur-map-open"

    if (container.classList.contains(closeMap)){
        container.classList.replace(closeMap, openMap)
        toogle.textContent = "Retour"
    } else{
        container.classList.replace(openMap, closeMap)
        toogle.textContent = "Voir la carte"
    }

    adjusingtMessageOverlayMap()
    adjustingAlignementInfoLabel()
    adjustingMapCenter()
}

//-- Use to open and close the Map (One Runner)  --
function handleClickToogleMapRunnerDesktop(e, device){
    //-- Toogle map suivi d'un coureur --
    let toggleText
    const panel = document.querySelector("#gr-fiche-coureur-panel-container")
    if(device === "desktop")  toggleText = document.querySelector("#gr-fiche-coureur-toggle-panel p")

    if(panel.classList.contains('gr-fiche-coureur-close-panel')){
        panel.classList.replace("gr-fiche-coureur-close-panel", "gr-fiche-coureur-open-panel")

        document.querySelector("#gr2022-infos-coureur-button-mobile span.openInfos").classList.add('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile svg.openInfos").classList.add('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile span.closeInfos").classList.remove('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile svg.closeInfos").classList.remove('uk-hidden')
        if(device === "desktop") toggleText.textContent = "fermer panel"
    } else{
        panel.classList.replace("gr-fiche-coureur-open-panel", "gr-fiche-coureur-close-panel")
        document.querySelector("#gr2022-infos-coureur-button-mobile span.openInfos").classList.remove('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile svg.openInfos").classList.remove('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile span.closeInfos").classList.add('uk-hidden')
        document.querySelector("#gr2022-infos-coureur-button-mobile svg.closeInfos").classList.add('uk-hidden')
        if(device === "desktop") toggleText.textContent = "ouvrir panel"
    }

    adjusingtMessageOverlayMap()

    adjustingAlignementInfoLabel()

    adjustingMapCenter()
}

//search bar
var state=null



//-- Initialization --
function initSearchBar(){
    initEventsListenersSearchBar()
}
function submitFormSearch(e){
  e.preventDefault()
  e.stopPropagation()
  state.submitter=true
  updateSuggestionsData()
}
//-- Initialize events listeners --
function initEventsListenersSearchBar(){
    //-- Event when value of the input change --
    state.searchFormLive.addEventListener('submit',(e)=>submitFormSearch(e));
    state.inputSearchBar.addEventListener('keyup', () => updateSuggestionsData() )
    state.inputSearchBar.addEventListener('click', (e) => {
        e.stopPropagation()
        updateSuggestionsData()
    })


    //-- Delete suggestion li when user click outside the input --
    window.addEventListener('click', () => {
        const suggestionsLi = document.querySelector("#gr-2022-header-list-suggestion ul").children
        if(suggestionsLi.length != 0) Array.from(suggestionsLi).forEach( li => li.remove() )
    })

}

//-- Update the suggestions data --
function updateSuggestionsData(){
  //console.log('updateSuggestionsData')
    let suggestions = []
    let errorMessage = null
    let cpt = 0
    //-- Array of value split by space --
    let inputValues = state.inputSearchBar.value.trim().split(' ')
    inputValues = inputValues.filter( value => value != '')

    if(inputValues.length == 0) {
        const suggestionsLi = document.querySelector("#gr-2022-header-list-suggestion ul").children
        if(suggestionsLi.length != 0) Array.from(suggestionsLi).forEach( li => li.remove())

        return
    }

    //-- Get the suggestion depending of input value --
    suggestions = Object.values(dataMultipleRunner.info).filter( runner => {
        const test = state.inputSearchBar.value.trim().toLowerCase() == runner.id_dossard.toString().toLowerCase()

        if(test) cpt++

        return test && cpt <= state.maxSuggestions

    })

    if (suggestions.length == 0) {

        suggestions = Object.values(dataMultipleRunner.info).filter( runner => {
            let test = true

            if(cpt <= state.maxSuggestions){
                inputValues.forEach(inputValue => {
                    if (inputValue != '' && test) {
                        const value = inputValue.toLowerCase()
                        test = runner.infos.nom.toLowerCase().startsWith(value) || runner.infos.prenom.toLowerCase().startsWith(value)
                    }
                })

                if(test) cpt++

            }

            return test && cpt <= state.maxSuggestions
        })
    }

    //-- Init error message --
    if (suggestions.length == 0 ){
        errorMessage = "Aucun résultat trouvé parmis les coureurs équipés de la balise Capturs. "
    } else if (suggestions.length == state.maxSuggestions){
        errorMessage = "Trop de résultats."
    }

    //-- Update the suggestions data and retrieve limit the content --
    state.suggestionsData = suggestions

    //-- Display suggestions --
    displaySuggestions(errorMessage)
}

//-- Display the list of suggestions --
function displaySuggestions(errorMessage){
    const suggestionsContainer = document.querySelector("#gr-2022-header-list-suggestion ul")

    //-- Remove the existing suggestions before display news --
    document.querySelectorAll("#gr-2022-header-list-suggestion ul li").forEach((item) => {
        item.remove()
    })

    if(errorMessage) {
        const lialt = document.createElement("li");
        lialt.classList.add('gr-search-bar-error-message')
        //lialt.textContent = errorMessage
        const newalt = document.createElement("a")
        const txtlink = document.createTextNode(errorMessage+" Cliquez ici pour rechercher sur Live Trail.")
        newalt.setAttribute("href", "https://grandraid-reunion-oxybol.livetrail.run/coureur.php?rech="+state.inputSearchBar.value.trim());
        newalt.setAttribute("target", "_blank")
        newalt.appendChild(txtlink)
        newalt.classList.add('link-to-livetrail')
        lialt.appendChild(newalt)
        suggestionsContainer.appendChild(lialt)

        //return // Exit the function to not display suggestions
    }
    else{
      //-- Create the suggestions elements --
      for (const suggestion of state.suggestionsData) {
          const newli = document.createElement("li");
          const dash = document.createTextNode("- ")
          const newa = document.createElement("a")
          newa.setAttribute("data-current-map", "trackingOneRunner")
          newa.setAttribute("data-id-dossard", suggestion.id_dossard)
          newa.addEventListener('click',handleChangeMap);

          const numElement = document.createElement('strong')
          numElement.textContent = "N° "

          const prenom = highlightSuggestion(suggestion.infos.prenom)
          prenom.classList.add('uk-text-capitalize')
          const nom = highlightSuggestion(suggestion.infos.nom)
          nom.classList.add('uk-text-uppercase')
          newa.append(numElement, highlightSuggestion(suggestion.id_dossard), dash, prenom, nom)

          newli.appendChild(newa)
          suggestionsContainer.appendChild(newli)
      }
    }
    if(state.submitter && state.suggestionsData.length==1){
      const toclick = suggestionsContainer.querySelector('a')
      simulateClick(toclick)
    }
    state.submitter=false;
}
function simulateClick(elem){
  var evt = new MouseEvent('click',{
    bubbles:true,
    cancelable:true,
    view:window
  });
  elem.dispatchEvent(evt);
}
//-- Highlight part of the suggestion --
function highlightSuggestion(suggestionWord){
    let highlightingText = document.createElement('span')
    highlightingText.textContent = suggestionWord+" "

    const word = suggestionWord.toString().toLowerCase()

    //-- Array of input values split by space --
    let inputValues = state.inputSearchBar.value.split(' ')
    //-- Retrieve all empty string of input values --
    inputValues =  inputValues.filter( value => value != '')
    //-- If the suggestionWord param begin by a value of the input then hightligh this one using strong tag --
    inputValues.forEach( inputValue => {
        if( word.startsWith(inputValue.toLowerCase()) ){
            const span = document.createElement('span')
            const strong = document.createElement('strong')
            strong.textContent = inputValue
            span.appendChild(strong)
            const remainingText = word.replace(inputValue.toLowerCase(), '')
            const remainingTextElement = document.createTextNode(remainingText+" ")
            span.appendChild(remainingTextElement)
            highlightingText = span
        }
    })

    return highlightingText
}

//Classement
function initClassement() {
    initEventsListenersClassement()
}

function initEventsListenersClassement(){
    //-- Ranking button on the header --
    document.querySelector("#gr-suivi-coureur-ranking-button").addEventListener('click', handlePanel)
    //-- Ranking CTA --
    document.querySelector("#gr-suivi-coureur-ranking-cta").addEventListener('click', handlePanel)
    //-- Toogle panel on mobile --
    document.querySelector("#gr-suivi-coureur-panel-ranking .gr-fiche-coureur-toggle-panel-mobile").addEventListener('click', handlePanel)

    //-- Button "classement" footer mobile --
    document.querySelector("#gr2022-classement-button-mobile").addEventListener('click', handlePanel)
    //-- Close Button Panel Ranking --
    document.querySelector(".gr-suivi-coureur-panel-ranking-cross-icon").addEventListener('click', handlePanel)


    //-- See ranking button on mobile --
    const buttons =  document.querySelectorAll("#gr-suivi-coureur-panel-ranking .gr-suivi-coureur-see-ranking-button")
    buttons.forEach( button => {
        button.addEventListener('click', (e) => {
            const container = e.currentTarget.closest(".gr-suivi-coureur-table-container")
            const openClass = "gr-suivi-coureur-table-container-open"
            const closeClass = "gr-suivi-coureur-table-container-close"

            if(container.classList.contains(closeClass)){
                container.classList.replace(closeClass, openClass)
                e.currentTarget.textContent = "Masquer le classement"
            } else{
                container.classList.replace(openClass, closeClass)
                e.currentTarget.textContent = "Voir le classement"
            }
        })
    })
}

function handlePanel(){
    const panel = document.querySelector("#gr-suivi-coureur-panel-ranking")
    const openClass = "gr-suivi-coureur-panel-ranking-open"
    const closeClass = "gr-suivi-coureur-panel-ranking-close"

    if(panel.classList.contains(closeClass)){
        initRankingTable()
        //-- Open the ranking panel --
        panel.classList.replace(closeClass, openClass)
        document.querySelector("span.openClassement").classList.add('uk-hidden')
        document.querySelector("svg.openClassement").classList.add('uk-hidden')
        document.querySelector("span.closeClassement").classList.remove('uk-hidden')
        document.querySelector("svg.closeClassement").classList.remove('uk-hidden')
    } else {
        //-- Close the ranking panel --
        panel.classList.replace(openClass, closeClass)
        document.querySelector("span.openClassement").classList.remove('uk-hidden')
        document.querySelector("svg.openClassement").classList.remove('uk-hidden')
        document.querySelector("span.closeClassement").classList.add('uk-hidden')
        document.querySelector("svg.closeClassement").classList.add('uk-hidden')
    }
}

function initRankingTable(){
    const tableContents = document.querySelectorAll("#gr-suivi-coureur-panel-ranking table tbody")

    //-- Remove existing elements from each table before refresh table data --
    tableContents.forEach( tbody => {
        Array.from(tbody.children).forEach( child => child.remove() )
    })

    fetch(baseURL+classementApiRoute, {
        method: 'GET'
    })
    .then( response => response.json())
    .then( data => {
        raceConfigs.forEach( race => {
            refreshTableData(data, race.idConfig, 'f')
            refreshTableData(data, race.idConfig, 'm')
        })
    })
}

function refreshTableData(data, course, sexe){
    const tableId = course.replaceAll(' ', '-').toLowerCase()+'-'+sexe.toLowerCase()
    const tbody = document.querySelector('#gr-ranking-table-'+tableId+' tbody')
    let nbRow = 0

    if(data.status == 'succes'){
            for (const runner of data.info) {
                if(runner.course == course && runner.sexe.toLowerCase() == sexe.toLowerCase()){
                    //----- CREATE ROW -----
                    //-- tr --
                    const tr = document.createElement('tr')
                    //-- td 1 --
                    const td1 = document.createElement('td')
                    td1.classList.add('uk-text-center', 'uk-text-capitalize')
                    const td1text = document.createTextNode(runner.clt)
                    td1.appendChild(td1text)
                    //-- td 2 --
                    const td2 = document.createElement('td')
                    td2.classList.add('uk-text-center', 'uk-text-capitalize')
                    const td2text = document.createTextNode(runner.dossard)
                    td2.appendChild(td2text)
                    //-- td 3 --
                    const td3 = document.createElement('td')
                    td3.classList.add('uk-text-center')
                    const span1 = document.createElement('span')
                    span1.classList.add('uk-text-capitalize')
                    span1.appendChild(document.createTextNode(runner.prenom))
                    const span2 = document.createElement('span')
                    span2.classList.add('uk-text-uppercase')
                    span2.appendChild(document.createTextNode(runner.nom))
                    td3.append(span1, document.createTextNode(' ') ,span2)
                    //-- td 4 --
                    const td4 = document.createElement('td')
                    td4.classList.add('uk-text-center', 'uk-text-capitalize')
                    const td4text = document.createTextNode(runner.temps)
                    td4.appendChild(td4text)

                    //----- APPENDS -----
                    //-- Append tr to tbody --
                    tbody.appendChild(tr)
                    //-- Append td to tr --
                    tr.append(td1, td2, td3, td4)

                    //----- INCREMENT NUMBER OF ROWS -----
                    nbRow++

                    //----- BREAK THE LOOPS DENPENDING NUMBER OF ROWS AUTHORIZED -----
                    if(nbRow == maxRowsRankingTable) break
                }
            }

            //------ DISPLAY A MESSAGE IF THERE IS NO DATA -----
            if(nbRow == 0){
                //-- tr --
                const tr = document.createElement('tr')
                //-- td --
                const td = document.createElement('td')
                td.setAttribute('colspan', '4')
                td.classList.add('uk-text-center')
                const tdText = document.createTextNode('Aucun résultat.')
                //-- appends --
                tbody.appendChild(tr)
                tr.appendChild(td)
                td.appendChild(tdText)
            }
        }

}
function fixProblemMapHeight(){
    const container = document.querySelector(".gr-suivi-coureur-pleine-hauteur")

    const fixHeight = () => {
      (currentMapMode == 'trackingOneRunner') ? container.classList.add('fix-height-map-one-runner') : container.classList.remove('fix-height-map-one-runner')
    }

    fixHeight()

    window.addEventListener('resize', fixHeight)
}



window.addEventListener('load',()=>{
    //console.log('Load')
    state={
        searchFormLive: document.querySelector("#searchFormLive"),
        inputSearchBar: document.querySelector(".gr-nav-container input"),
        suggestionsData: null,
        maxSuggestions: 5,
        runners: null,
        submitter:false
    }

    init()
    initSearchBar()
    initClassement()
});
